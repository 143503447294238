import { reactive, ref } from 'vue'

export const globalSettings = {
	data() {
		return {
			isProductionEnvironment: process.env.NODE_ENV != 'development',

			//apiUrl: window.location.pathname.toLowerCase().includes("master") ? "/api" : "http://localhost:5000/api",
			//apiUrl: process.env.NODE_ENV != 'development' ? "/api" : "http://192.168.0.100:5009/api",
			apiUrl: process.env.NODE_ENV != 'development' ? "/api" : "https://aptis.oblio.top/api",

			appVersion: "2.0.5",
			controllerPairingMethod: 2,	// 1 - use the original method, with http requests to the controller IP. 2 - use the regisration method with unique id (part of the controller stamp id)

			deepClone: function(obj) {
				return JSON.parse(JSON.stringify(obj));
			},
			/*======= Array unique ids ========*/
			addUniqueIds: function(arr, idFieldName) {
				/* Adds a field with [idFieldName] name and an unique value to each element in the [arr] array */
				var uniqueVal = 0;
				arr.forEach(element => {
					element[idFieldName] = ++uniqueVal;
				});
			},
			getUniqueId: function(arr, idFieldName) {
				/* Gets the first integer value not appearing in the [idFieldName] of the [arr] array's elements */
				var uniqueId = 1;
				try {
					while(arr.findIndex(element => element[idFieldName] == uniqueId) != -1) {
						uniqueId++;
					}
				}
				catch(err) {
					return -1;
				}
				return uniqueId;
			},
			findBestChannelMatch: function(channels, channelId) {
				var channel = channels.find(channel => channel.channelId == channelId);
				return (channel != null ? channel : channels[0]);
			},

			browseTree: function(treeObj, executeOnNode) {
				treeObj.forEach(node => {
					this.browseNode(node, executeOnNode);
				});
			},
			browseNode: function(node, executeOnNode) {
				if (executeOnNode != null) {
					executeOnNode(node);
				}
				node.children.forEach(child => {
					this.browseNode(child, executeOnNode);
				});
			},
		
			notifPermission: {
				denied: "denied",
				granted: "granted",
				default: "default"
			},
			enumPresenter: {
				getName: function(presenter, val) {
					var element = presenter.find(el => el.value == val);
					return (element != null ? element.name : "???");
				},
				actualDeviceMountStates: [
					{ value: -1, name: "Undetermined" },
					{ value: 0, name: "Inactive" },
					{ value: 1, name: "Mounted on controller" },
					{ value: 2, name: "Set to mount, not mounted on controller" }
				],
				deviceControlTypes: [
					{ value: 1, name: "Get value" },
					{ value: 2, name: "Get average value" },
					{ value: 3, name: "Get state" },
					{ value: 4, name: "Set constant value" },
					{ value: 5, name: "Output value" },
					{ value: 6, name: "On / Off" }
				],
				//{ None = 0, Minutely = 1, Hourly = 2, Daily = 3, Weekly = 4, Monthly = 5, Yearly = 6, DayOfWeek = 7, DayOfMonth = 8, Custom = 9 }
				recurrenceTypes: [
					//{ value: 0, name: "None" },
					//{ value: 1, name: "Minutely" },
					//{ value: 2, name: "Hourly" },
					//{ value: 3, name: "Daily" },
					//{ value: 4, name: "Weekly" },
					//{ value: 5, name: "Monthly" },
					//{ value: 6, name: "Yearly" },
					//{ value: 7, name: "Day of Week" },
					//{ value: 8, name: "Day of Month" },
					{ value: 9, name: "Custom", ml: "custom" },
					{ value: 10, name: "Week days", ml: "week_days" },
					{ value: 11, name: "Month days", ml: "month_days" }
				],
				recurrenceUnit: [
					// { value: 0, name: "None" },
					// { value: 1, name: "Second" },
					{ value: 2, name: "Minute", ml: "minutes" },
					{ value: 3, name: "Hour", ml: "hours" },
					{ value: 4, name: "Day", ml: "days" },
					// { value: 5, name: "Month" },
					// { value: 6, name: "Year" }
				],
				jobManagerState: [
					{ value: 0, name: "None" },
					{ value: 1, name: "Stopped" },
					{ value: 2, name: "Running" },
					{ value: 3, name: "Executing" },
				],
				jobManagerStateColors: [
					{ value: 0, name: "grey" },
					{ value: 1, name: "#ff5555" },
					{ value: 2, name: "green" },
					{ value: 3, name: "blue" },
				],
				// Admin rights. Bitmap values (0, 1, 2, 4, etc). For now only value 0 (none) and 1 (-the user has all administrator rights)
				adminRights: {
					none: 0, details: { name: "None" },
					superAdmin: 1, details: { name: "Super User" },
				},
				// In the following array, if the displayed day order is to ne changed (for example Sunday as first day, only the value of daysMapIndex field must be changed, pointing to the corresponding element in the days map array).
				daysOfWeek: [
					{value: 0, label: "Monday", ml: "monday"},
					{value: 1, label: "Tuesday", ml: "tuesday"},
					{value: 2, label: "Wednesday", ml: "wednesday"},
					{value: 3, label: "Thursday", ml: "thursday"},
					{value: 4, label: "Friday", ml: "friday"},
					{value: 5, label: "Sathurday", ml: "sathurday"},
					{value: 6, label: "Sunday", ml: "sunday"}
				],
				commandTypes: [
					// {value: 0, name: "None", ml: "none"},
					{value: 1, name: "Get value", ml: "query"},
					{value: 3, name: "Set value", ml: "command"}
				],
				getOperators: [
					{value: "LT", label: "<", ml: ""},
					{value: "LT=", label: "<=", ml: ""},
					{value: "==", label: "==", ml: ""},
					{value: "GT", label: ">", ml: ""},
					{value: "GT=", label: ">=", ml: ""},
					{value: "InInterval", label: "->[ ]<-", ml: ""},
					{value: "OutsideInterval", label: "<-[ ]->", ml: ""},
					{value: "getOnOff", label: "On/Off", ml: ""}
				],
				setOperators: [
					{value: "=", label: "=", ml: ""},
					{value: "setOnOff", label: "On/Off", ml: ""}
				],
				weatherParams: [
					{ value: 1, name: "Precipitation", ml: "precipitations" },
					{ value: 2, name: "Humidity", ml: "humidity" },
					{ value: 3, name: "Temperature", ml: "temperature" }
				],
				childUserRights: [
					{ value: 1, name: "Can view", ml: "can_view" },
					{ value: 2, name: "Can execute", ml: "can_execute" },
					{ value: 3, name: "Can edit", ml: "can_edit" },
				]
			}
		}
	},
	methods: {
		showWait: function(show) {
			this.goptions.waitPopupVisible = show;
		},
		getNameFromVal: function(collection, val, defaultValue) {
			var foundVal = collection.find(element => element.value == val);
			return foundVal !== undefined ? foundVal.name : defaultValue;
		},
		getMlidFromVal: function(collection, val, defaultValue) {
			var foundVal = collection.find(element => element.value == val);
			return foundVal !== undefined ? foundVal.ml : defaultValue;
		},
		serialCommandExecute: function(controllerId, commandBody, timeout, includesMessageId) {
			return this.$ky(this.apiUrl + "/SerialCommandExecute", {
				method: "GET",
				searchParams: {
					controllerId: controllerId,
					commandBody: commandBody,
					timeout: timeout,
					includesMessageId: includesMessageId
				},
				cache: "no-cache",
				timeout: timeout + 1000,
				retry: 1
			})
			.json();
		},
		getUserControllers: function() {
			return this.$ky(this.apiUrl + "/GetControllers", {
				method: "GET",
				headers: { "Authorization": this.authHeaderVal },
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json();
		},
		getUserDevices: function() {
			return this.$ky(this.apiUrl + "/GetAllUserDevices", {
				method: "GET",
				headers: { "Authorization": this.authHeaderVal },
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json();
		},
		getUserJobs: function() {
			return this.$ky(this.apiUrl + "/GetJobs", {
				method: "GET",
				headers: { "Authorization": this.authHeaderVal },
				searchParams: {
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json();
		},
		getJobManagerInfo: function() {
			return this.$ky.get(this.apiUrl + "/JobManagerGetInfo", {
				searchParams: {},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json();
		},
		getJobManagerJobs: function() {
			return this.$ky.get(this.apiUrl + "/GetJobManagerInternalJobs", {
				headers: { "Authorization": this.authHeaderVal }
			})
			.json()
		},
		isJobExecuting: function(jobId) {
			return this.$ky.get(this.apiUrl + "/IsJobExecuting", {
				searchParams: {jobId: jobId}, cache: "no-cache", timeout: this.$gconst.timeouts.medium, retry: 1})
			.json()
		},
		updateControllerState: function(controller) {
			/* Updates the controller in place, with the MQTT client info
			and ESP controller information (i.e. signal strength etc.) */
			this.$ky(this.apiUrl + "/GetMqttClient", {
				method: "GET",
				searchParams: {
					mqttClientId: controller.stampId
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json()
			.then((responseJson) => {
				if (responseJson.value != null) {
					controller.mqttClient = reactive(responseJson.value);
				}
				else {
					controller.mqttClient = ref(responseJson.value);
				}
				// Get controller info - for example WiFi strength
				this.updateControllerInfo(controller);
			})
			.catch((err) => {
				alert("Error (X): " + err.message);
			})
		},
		updateControllerInfo: function(controller) {
			/* Updates the controller in place, with
			the ESP controller information (i.e. signal strength etc.).
			Presumes that the MQTT client info is already injected in the controller.
			=> This function should only be called by getControllerState() or any other function
			thet sets the MQTT client info a the controller level*/
			if (controller.mqttClient != null) {	// controller is online
				this.serialCommandExecute(controller.controllerId, "sgci", 3000, false)
				.then((responseJson) => {
					//console.log(responseJson);
					if (responseJson.success) {
						var info = {};
						var infoItems = responseJson.value.messageDetails.split("|");
						infoItems.forEach(atom => {
							var atoms = atom.split("=");
							// Convert values to the correct type (numeric, bool, etc) and normalize values if case.
							switch(atoms[0]) {
								case "signalStrength":
									atoms[1] = parseInt(atoms[1]);
									atoms[1] = Math.max(Math.min(atoms[1], 100), 0);
									break;
							}
							info[atoms[0]] = atoms[1];
						});
						controller.info = reactive(info);
					}
					else {
						console.log("NOT SUCCESS");
						controller.info = ref(null);
					}
				})
				.catch((err) => {
					controller.info = ref(null);
				});
			}
			else {	// Controller is offline
				controller.info = ref(null);
			}
		},
		getPairingControllerByStampId: function(stampId) {
			return this.$ky(this.apiUrl + "/GetControllerByStampId", {
				method: "GET",
				searchParams: {
					stampId: stampId
				},
				cache: "no-cache",
				timeout: this.$gconst.timeouts.medium,
				retry: 1
			})
			.json();
		},
		getControllerStateColor: function(controller) {
			if (controller.mqttClient != null) {
				return "#00aa00";
			}
			if (controller.mqttClient === null) {
				return "#aa0000";
			}
			else {
				return "#aaaa00";
			}
		},
		getFlatInfo: async function() {
			var retObj = {controllers: [], devices: [], jobs: []};
			var values = null;
			try {
				//values = await this.executeAll();
				values = await Promise.all([
					this.getUserControllers(),
					this.getUserDevices(),
					this.getUserJobs()
				]);
			}
			catch(err) {
				console.error("Failded to call entitied functions:\n" + err.message);
				return retObj;
			}
			retObj.controllers = values[0].value;
			retObj.devices = values[1];
			retObj.jobs = values[2];

			// Split the job's if actions and chain links' actions, and collect the device ids.
			retObj.jobs.forEach(job => {
				job.ifDevIds = this.getLinkDeviceIds(job.ifLink.tasksStr, [0]);
				job.chainLinks.forEach(chainLink => {
					job.execDevIds = this.getLinkDeviceIds(chainLink.tasksStr, [0]);
				});
				job.execDevIds = [... new Set(job.execDevIds)];	// distinct values
				job.allDevIds = [... new Set(job.ifDevIds.concat(job.execDevIds))];
			});

			return retObj;
		},
		getLinkDeviceIds: function(actionsStr, filterChannels /* array of int */) {
			var retArr = [], atoms = [];
			actionsStr.split(",").forEach(actionStr => {
				atoms = actionStr.split(":");
				atoms[0] = parseInt(atoms[0]);	// Action channel
				atoms[1] = parseInt(atoms[1]);	// Action target device id
				if (filterChannels.includes(atoms[0])) {	// accepted channel
					retArr.push(atoms[1]);
				}
			})
			return retArr;
		},
		getChildren: function(flatInfo, objId, objType) {
			var retArr = [];
			switch(objType) {
				case "controller":
					retArr = flatInfo.devices.filter(device => device.controllerId == objId);
					break;
				case "device":
					retArr = flatInfo.jobs.filter(job => job.allDevIds.includes(objId));
					break;
			}
			return retArr;
		},
		daysMapFromValue: function(recurrenceValue) {
			var str = recurrenceValue.toString(2).padStart(31, "0");
			return [...str].reverse().map(x => parseInt(x)) ;
		},
		daysMapToValue: function(daysMap) {
			var str = daysMap.reverse().join("");
			return parseInt(str, 2);
		},
		actionDeserialize: function(actionStr, devices) {
			var atoms = actionStr.split(":");
			var domain = parseInt(atoms[0]);
			if (domain == 0) {
				var device = devices.find(device => device.dbId == atoms[1]);
				return {domain: domain, device: device, operator: atoms[2], value: parseInt(atoms[3])};
			}
			else if (domain == 1) {
				return {domain: domain, deviceId: parseInt(atoms[1]), operator: atoms[2], value: parseFloat(atoms[3])};
			}
		},
		actionSerialize: function(action) {
			if (action.domain == 0) {
				return action.domain + ":" + action.device.dbId + ":" + action.operator + ":" + action.value;
			}
			else if (action.domain == 1) {
				return action.domain + ":" + action.deviceId + ":" + action.operator + ":" + action.value;
			}
		},
		actionText: function(action) {
			switch(action.domain) {
				case 0:
					var opStr = "???";
					var opVal = "???";
					if (this.isOutputAction(action)) {
						if (action.operator == "setOnOff") {
							opStr = "⇒";
							opVal = (action.value == 0 ? "Off" : "On");
						}
						else if (action.operator == "=") {
							opStr = "=";
							opVal = action.value;
						}
					}
					else  {		// One of the Get operators
						if (action.operator == "getOnOff") {
							opStr = "Is";
							opVal = (action.value == 0 ? "Off" : "On");
						}
						else {
							opStr = this.enumPresenter.getOperators.find(op => op.value == action.operator).label;
							opVal = action.value;
						}
					}
					return action.device.deviceName + " " + opStr + " " + opVal;
				case  1:	// Weather
					var weatherParam = this.enumPresenter.weatherParams.find(param => param.value == action.deviceId);
					var opStr = this.enumPresenter.getOperators.find(op => op.value == action.operator).label;
					return this.ML.trans(weatherParam.ml) + " " + opStr + " " + action.value;
				default:
					return "??? Unknown domain"
			}
		},
		isOutputAction: function(action) {
			return (this.enumPresenter.setOperators.findIndex(op => op.value == action.operator) != -1);
		},
		installPwaApp: function() {
			// Wait for the user to respond to the prompt
			this.goptions.serviceWorkerState.deferredInstallPrompt.prompt();
			// Proceed according to the user response (accept / dismiss)
			this.goptions.serviceWorkerState.deferredInstallPrompt.userChoice.then((choiceResult) => {
				if (choiceResult.outcome === 'accepted') {
					console.log('User accepted the A2HS prompt');
					this.goptions.serviceWorkerState.deferredInstallPrompt = null;
				} else {
					console.log('User dismissed the A2HS prompt');
				}
			});
		},
		hasMinRights: function(minRight) {
			return (this.goptions.currentuser.isPrincipal ? true : this.goptions.currentuser.rights >= minRight);
		},
		hasMaxRights: function(maxRight) {
			console.log("hasMaxRights", maxRight, this.goptions.currentuser.rights)
			return (this.goptions.currentuser.isPrincipal ? true : this.goptions.currentuser.rights <= maxRight);
		},
		isPrincipal: function() {
			return this.goptions.currentuser.isPrincipal;
		}
	}
}
