import { globalSettings } from "@/components/mixins/globalSettings";
import {gconst} from "@/components/mixins/gconst";
import { datesLib } from "@/components/mixins/datesLib";
import ky from "ky";

export const initialSetup = {
	initializeAppState: async function(store) {
		// Restore the local setting or set its values to default.
		this.setupRestoreLocalSettings(store);

		var token = this.setupGetAuthStoredToken();
		if (token == null) {
			// Update loggedUser = null and token = null in globalSettings
			store.state.goptions.currentuser = null;
			store.state.goptions.cachedJwt = null;
			return;
		}
		
		try {
			var authResult = await this.setupAuthorizeUser(token.jwt);
			if (authResult.success) {
				// Update loggedUser and token in globalSettings.
				store.state.goptions.currentuser = authResult.value;
				store.state.goptions.cachedJwt = token.jwt;

				token.lastDateTimeStr = datesLib.data().dateToIsoStr(new Date(), "YMD");
				localStorage.token = JSON.stringify(token);
			}
			else {
				// Update loggedUser = null and token = null in globalSettings.
				localStorage.removeItem("token");
				store.state.goptions.currentuser = null;
				store.state.goptions.cachedJwt = null;
				console.log("[debug] initializeAppState --> Not authorized");
			}
		}
		catch(err) {
			// Update loggedUser = null and token = null in globalSettings. Remove the token from localSettings.
			localStorage.removeItem("token");
			store.state.goptions.currentuser = null;
			store.state.goptions.cachedJwt = null;
			console.log("[debug] initializeAppState --> error/catch");
			console.log(err);
		}
		console.log("===> App data initialization done");
	},
	setupGetApiUrl: function() {
		return globalSettings.data().apiUrl;
	},
	setupGetAuthStoredToken: function() {
		var storedtoken = localStorage.getItem("token");
		return (storedtoken != null ? JSON.parse(storedtoken) : null);
	},
	setupAuthorizeUser: function(jwtStr) {
		return ky.get(this.setupGetApiUrl() + "/Authorize", {
			headers: {
				"Authorization": "Bearer " + jwtStr
			},
			cache: "no-cache",
			timeout: gconst.timeouts.medium,
			retry: 1
		  })
		.json();
	},
	setupRestoreLocalSettings: function(store) {
		if (localStorage.settings == null) {
			localStorage.settings = {};		// The default values are set below.
			this.setupSaveLocalSettings(store);
		}
		store.state.goptions.localSettings = JSON.parse(localStorage.getItem("settings"));

		// Default values
		var s = store.state.goptions.localSettings;
		s.sessionTimeout = s.sessionTimeout == null ? 0 : s.sessionTimeout;
		s.language = (s.language == null ? "en" : s.language);
		s.darkMode = (s.darkMode == null ? true : s.darkMode);
		s.dateFormat = (s.dateFormat == null ? "DMY" : s.dateFormat);
		s.logPageSize = s.logPageSize == null ? 20 : s.logPageSize;
		s.keepMeSigned = s.keepMeSigned == null ? false : s.keepMeSigned;
		this.setupSaveLocalSettings(store);
	},

	setupSaveLocalSettings: function(store) {
		localStorage.settings = JSON.stringify(store.state.goptions.localSettings);
	},
}
